#success {
  @include media-breakpoint-up(lg) {
    margin-top: 15rem;
  }

  h3.d-block {
    margin-top: 2rem;
  }
  // padding-bottom: 7.5rem;

  .gatsby-image-wrapper {
    max-height: 20rem;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: -7.5rem;
      max-height: unset;
    }
  }
}
