.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.sidebarred {
  @each $breakpoint in map-keys($container-max-widths) {
    @if ($breakpoint!= "sm") {
      @include media-breakpoint-up($breakpoint) {
        $container: map-get($container-max-widths, $breakpoint);
        $width: calc(#{$container}/ 100 * 48.66666);
        padding-right: $width;
      }
    }
  }
  &__alt {
    @include media-breakpoint-up(md) {
      padding-right: 300px;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 430px;
    }
    // @include media-breakpoint-up(xl) {
    //   $container: map-get($container-max-widths, 'xl');
    //   padding-right: calc( ((100% - (#{$container} * 2)) / 2 ) + 320px);
    // }
    @media (min-width: 1600px) {
      padding-right: 200px;
    }
    // $container: map-get($container-max-widths, 'lg');
    //   @include media-breakpoint-up(lg) {
    //     padding-right: calc( ((100% - #{$container}) / 2 ) - 320px);
    //   }
    @media (min-width: 2100px) {
      padding-right: 0;
    }
  }
}

.bg-black {
  background-color: $black;
  color: $white;
}

.grid {
  display: none;
  position: fixed;
  z-index: +99999;
  height: 100%;
  width: 100%;
  top: 0;
  pointer-events: none;

  &.container {
    left: 50%;
    transform: translateX(-50%);

    &:before,
    &:after {
      content: " ";
      background: $white;
      top: 0;
      width: 50vw;
      height: 100%;
      position: fixed;
      opacity: 0.2;
    }

    @each $breakpoint in map-keys($container-max-widths) {
      @include media-breakpoint-up($breakpoint) {
        $container: map-get($container-max-widths, $breakpoint);
        $width: calc(50% + (#{$container}/ 2));
        &:before {
          left: $width;
        }
        &:after {
          right: $width;
        }
      }
    }
  }

  [class*="col-"] {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 100vh;
      border-left: 1px solid rgba($danger, 0.2);
      border-right: 1px solid rgba($danger, 0.2);
    }
    &:before {
      left: ($grid-gutter-width/2);
    }
    &:after {
      right: ($grid-gutter-width/2);
    }
  }
}

.opacity-0 {
  opacity: 0 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-40 {
  opacity: 0.4 !important;
}
.opacity-60 {
  opacity: 0.6 !important;
}
.opacity-80 {
  opacity: 0.8 !important;
}
.opacity-100 {
  opacity: 1 !important;
}

.diagonal_bg {
  background-color: transparent !important;
  position: relative;
  &:before {
    transform: skewY(-5deg);
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @each $key, $val in $theme-colors {
    &.bg-#{$key} {
      &:before {
        background-color: #{$val} !important;
      }
    }
  }
}
.fix-diagonal {
  z-index: 0;
  position: relative;
  &:before {
    pointer-events: none;
    content: "";
    transform: skewY(-5deg);
    z-index: -1;
    position: absolute;
    top: -4rem;
    left: 0;
    width: 100%;
    height: calc(100% - 5rem);
  }
  @each $key, $val in $theme-colors {
    &.bg-#{$key} {
      &:before {
        background-color: #{$val} !important;
      }
    }
  }
}

.bg-half-down {
  z-index: 0;
  background: none !important;

  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    pointer-events: none;
    content: "";
    z-index: -1;
  }
  @each $key, $val in $theme-colors {
    &.bg-#{$key} {
      &:before {
        background-color: #{$val} !important;
      }
    }
  }
}

.bg-half-up {
  position: relative;
  background: none !important;
  z-index: 0;

  &:before {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 50%;
    pointer-events: none;
    content: "";
    z-index: -100;
  }
  @each $key, $val in $theme-colors {
    &.bg-#{$key} {
      &:before {
        background-color: #{$val} !important;
      }
    }
  }
}

h1.text-white,
h2.text-white,
h3.text-white,
h4.text-white,
h5.text-white,
h6.text-white,
.text-white .h1,
.text-white .h2,
.text-white .h3,
.text-white .h4,
.text-white .h5,
.text-white .h6,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white.h1,
.text-white.h2,
.text-white.h3,
.text-white.h4,
.text-white.h5,
.text-white.h6 {
  color: $white;
}

// $stars: (1, 2, 3, 4, 5);
// .star- {
//   @each $val in $stars {
//     & #{$val} {
//       width: #{$val}rem;
//     }
//   }
// }

@each $key, $val in $theme-colors {
  .bg-#{$key},
  .bg-#{$key} a:not(.btn) {
    @include get-color-yiq($val);
  }
}

@each $key, $val in $theme-colors {
  .journal-#{$key} {
    .journal_date,
    .journal_title,
    .journal_category,
    .journal_category a,
    a h2,
    h1,
    h2,
    h3,
    h4 {
      color: $val !important;
    }
    hr {
      border-color: $val;
    }
  }
}

.negative-margin {
  // padding-top: 5rem;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 10rem;
  }
  &_inner {
    // margin-top: -8rem;
    @include media-breakpoint-up(md) {
      margin-top: -7rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -10rem;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -15rem;
    }
  }
  &-double {
    .negative-margin_inner {
      // margin-top: -14rem;
      @include media-breakpoint-up(md) {
        margin-top: -6rem;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -10rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -20rem;
      }
    }
  }
  &-triple {
    .negative-margin_inner {
      // margin-top: -14rem;
      @include media-breakpoint-up(md) {
        margin-top: -30rem;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -30rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -30rem;
      }
    }
  }

  &-bottom {
    .negative-margin_inner {
      @include media-breakpoint-up(md) {
        margin-bottom: -5rem;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: -5rem;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: -10rem;
      }
    }
  }
}

.hubspot_form {
  #form {
    margin-top: -3rem;
    @include media-breakpoint-up(lg) {
      margin-top: -15rem;
      .sticky-top {
        top: 9rem;
      }
    }
    @include media-breakpoint-up(xl) {
      margin-top: -20rem;
    }
  }
}
.wysiwyg {
  h1,
  h2,
  h3,
  h4 {
    margin-top: 2rem;
    // @extend .text-secondary;
  }

  ol {
    margin-top: 1rem;
    list-style: none;
    counter-reset: my-awesome-counter;
    ul,
    ol {
      margin-top: 0.5rem !important;
    }

    > li {
      counter-increment: my-awesome-counter;
      margin-bottom: 0.5rem;

      &:before {
        content: counter(my-awesome-counter) ". ";
        color: $secondary;
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }

  ul {
    margin-top: 1rem;
    list-style: none;
    ul,
    ol {
      margin-top: 0.5rem !important;
    }

    > li {
      margin-bottom: 0.5rem;

      &:before {
        content: "+";
        color: $secondary;
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }
}

@each $key, $val in $theme-colors {
  .bg-#{$key}-grad-dark {
    background-image: linear-gradient(
      180deg,
      darken($val, 0%) 0%,
      darken($val, 5%) 100%
    );
  }
}
@each $key, $val in $theme-colors {
  .bg-#{$key}-grad {
    background-image: linear-gradient(
      145deg,
      $primary 0%,
      darken($val, 0%) 100%
    );
  }
}
.bg-primary-grad,
.bg-grad-main {
  background-image: linear-gradient(
    135deg,
    #1f1a3c 0%,
    #502246 100%
  ) !important;
}

@each $key, $val in $theme-colors {
  .bg-#{$key}-grad-down {
    background-image: linear-gradient(0deg, $primary -50%, $val 100%);
  }
}

.z-index-100 {
  z-index: +999;
}

.coverbg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

input.input-lg {
  font-size: 1.5rem;
  font-weight: bold;
  color: $black;
  background: $gray-300 !important;
  transition: 0.2s ease all !important;
  &:hover {
    border-color: $primary !important;
  }
  &:focus,
  &:active {
    color: $black !important;
    box-shadow: none !important;
    background: $white !important;
  }
}

.w-80 {
  max-width: 80%;
  margin: auto;
}
.w-60 {
  max-width: 60%;
  margin: auto;
}
.w-40 {
  max-width: 40%;
  margin: auto;
}

.border-thick {
  border-width: 0.15rem !important;
}

.input-group-text.border-black {
  border-color: $black;
}

.input-currency-prepend {
  padding-left: 0;
}

.row.contained-xl {
  max-width: 2200px !important;
  @media (min-width: 2200px) {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 3000px) {
  #mainnavbar .navbar {
    max-width: 2200px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@each $breakpoint in map-keys($container-max-widths) {
  @include media-breakpoint-up($breakpoint) {
    .display-#{$breakpoint}-1 {
      font-size: $display1-size;
      font-size: $display1-weight;
      line-height: 0.9;
    }
    .display-#{$breakpoint}-3 {
      font-size: $display3-size;
      font-size: $display3-weight;
    }
  }
}
