.form {
  &-min-height {
    min-height: 600px;
  }
  &-multistep {
    h2 {
      text-align: center;
    }

    .smaller-row {
      max-width: 600px !important;
      margin: auto;
    }
    .form-group-row > label {
      @extend .h3, .mb-2;
      text-align: center !important;
    }
    .form-group {
      .input {
        margin: auto;
      }
    }
    .custom-control {
      text-align: left;

      input:focus + label {
        border: 1px solid $success !important;
      }

      label:hover,
      input.checked + label,
      input:checked + label {
        background: $secondary;
        border-color: $white !important;
        // color: $white !important;
        // &:after{
        //   background: $input-border-color;
        // }
      }
      input.checked + label,
      input:checked + label {
        background: $secondary;
        border-color: $secondary !important;
        color: $white !important;
        // &:after{
        //   background: $input-border-color;
        // }
      }
      label {
        font-size: 1.2rem;
        background: transparent;
        font-family: $headings-font-family;
        line-height: 1.2;
        margin-bottom: 0;
        display: block;
        position: relative;
        border: $input-border-width solid $input-border-color;
        color: $white;
        padding: $input-padding-y $input-padding-x;
        padding-right: 4rem;
        border-radius: $border-radius-lg;
        transition: 0.66s ease all !important;
        font-weight: bold;
        cursor: pointer;
        text-align: center !important;
        &:after {
          content: '';
          position: absolute;
          right: 1rem;
          top: 1rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: $input-border-width solid $input-border-color;
          transition: 0.3s ease all;
        }
      }
      .custom-radio {
        display: block;
        width: 100%;
      }
    }
    .btn {
      margin-top: 1rem;
    }

    .hide-labels {
      label {
        display: none;
      }
      .options {
        label {
          display: block;
        }
      }
    }

    .hide-options-labels {
      .options {
        label {
          text-indent: -999999px;
        }
      }
    }
    .form {
      label {
        // @extend .h5;
      }

      .form-group-row {
        @extend .mb-4;
      }

      &.labels-left,
      .labels-left {
        .form-group-row {
          @extend .row;
          max-width: 600px !important;
          margin: auto;

          label {
            @extend .col-md-3;
          }
          .input {
            @extend .col-md-9;
          }
        }
      }
    }
    .custom-control-input-wrapper {
      .option {
        @extend .custom-control,
          .custom-radio,
          .mb-4,
          .position-relative,
          .d-block;
      }
      input {
        @extend .custom-control-input;
      }
    }

    .custom-select-wrapper {
      select {
        @extend .custom-select;
        &::-ms-expand {
          display: none;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
      }
    }

    .TwoColInput {
      .form-group-row {
        @extend .row;
        max-width: 600px !important;
        margin: auto;

        label {
          @extend .col-12, .mb-3;
        }
        .input {
          @extend .col-12, .col-md-10, .col-lg-8, .col-lg-6;
          .options {
            @extend .row, .text-center;
            .option {
              @extend .col-6;
              padding-left: 15px !important;
            }
          }
        }
      }
    }
    .ThreeColInput {
      .form-group-row {
        @extend .row;
        label {
          @extend .col-12;
        }
        .input {
          @extend .col-12;
          .options {
            @extend .row, .text-center, .justify-content-center, .mt-3;
            .option {
              @extend .col-6, .col-lg-4;
              padding-left: 15px !important;
              label {
                @extend .h-100;
              }
            }
          }
        }
      }
    }
    .FourColInput {
      .form-group-row {
        @extend .row;
        label {
          @extend .col-12;
        }
        .input {
          @extend .col-12;
          .options {
            @extend .row, .text-center, .justify-content-center, .mt-3;
            .option {
              @extend .col-6, .col-lg-3;
              padding-left: 15px !important;
              label {
                @extend .h-100;
              }
            }
          }
        }
      }
    }
    .SixColInput {
      .form-group-row {
        @extend .row;
        label {
          @extend .col-12;
        }
        .input {
          @extend .col-12;
          .options {
            @extend .row, .text-center, .justify-content-center, .mt-3;
            .option {
              @extend .col-6, .col-sm-4, .col-md-3;
              padding-left: 15px !important;
              @include media-breakpoint-up(lg) {
                max-width: 20%;
                flex: 0 0 20%;
              }
              label {
                @extend .h-100;
              }
            }
          }
        }
      }
    }

    .buttons {
      .options label {
        @extend .shadow, .h-100;
        text-align: center;
        display: block;
        position: relative;
        width: 100%;
        padding: 80% 15px 10% 15px !important;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        font-size: 1rem;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0.75rem;
          left: 10%;
          width: 80%;
          height: 60%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: 0.3s ease all;
        }
        &:hover {
          @extend .shadow-lg;
          &:before {
          }
        }
      }
    }
    .buttons #SUPPLIER_Other + label {
      text-indent: 0 !important;
      // padding: 3.33rem 0 !important;
    }
  }
}

.buttons .form-group-row[data-field='SUPPLIER'] {
  .option label {
    padding: 25% 15px 10% 15px !important;
  }
}

// $button_icons: (
//   "SUPPLIER_British_Gas": "../images/icons/british-gas.png",
//   "SUPPLIER_Bulb": "../images/icons/bulb.png",
//   "SUPPLIER_EDF": "../images/icons/edf.png",
//   "SUPPLIER_Eon": "../images/icons/eon.png",
//   "SUPPLIER_npower": "../images/icons/npower.png",
//   "SUPPLIER_Octopus_Energy": "../images/icons/octopus-energy.png",
//   "SUPPLIER_Ovo_Energy": "../images/icons/ovo.png",
//   "SUPPLIER_Scottish_Power": "../images/icons/scottish-power.png",
//   "SUPPLIER_Shell_Energy": "../images/icons/shell-energy.png",
//   "SUPPLIER_SSE": "../images/icons/sse-logo.png",
//   "SUPPLIER_Other": "../images/icons/other.png",
//   "SMARTMETER_N": "../images/icons/meter.svg",
//   "SMARTMETER_Y": "../images/icons/smart-meter.svg",
//   "BILLS_monthly": "../images/icons/monthly.svg",
//   "BILLS_pre": "../images/icons/pre.svg",
//   "BILLS_quarterly": "../images/icons/quarterly.svg",
//   "BILLS_receipt": "../images/icons/invoice.svg",
// );

// @each $id, $img in $button_icons {
//   ##{$id} + label {
//     &:before {
//       background-image: url(#{$img});
//     }
//   }
// }

// $button_icons_hover: (
//   "SMARTMETER_N": "../images/icons/meter-white.svg",
//   "SMARTMETER_Y": "../images/icons/smart-meter-white.svg",
//   "BILLS_monthly": "../images/icons/monthly-white.svg",
//   "BILLS_pre": "../images/icons/prepay-white.svg",
//   "BILLS_quarterly": "../images/icons/quarterly-white.svg",
//   "BILLS_receipt": "../images/icons/invoice-white.svg",
// );

// @each $id, $img in $button_icons_hover {
//   // ##{$id} + label:hover,
//   ##{$id}:checked + label {
//     &:before {
//       background-image: url(#{$img});
//     }
//   }
// }

.btn-lg {
  transition: 0.3s ease all !important;
  &:hover,
  &:focus {
    transform: scale(1.05) !important;
  }
}

.bg-dark {
  .material {
    .form-group-row {
      margin-bottom: 0 !important;

      input {
        margin-top: 2rem;
      }
      > label {
        color: $dark !important;
        background-color: $white;
      }
      &.label-animate > label {
        transform: translateY(0%);
        top: -22px !important;
        left: 12px !important;
        font-size: 0.9em;
        color: $white !important;
        background-color: transparent;
        // background: linear-gradient(180deg, $dark 0%, $dark 50%, $white 50%, $white 100%);
      }
    }
  }
}
.material {
  .form-group-row {
    input {
      background-color: white;
      color: $dark !important;
    }
    position: relative;
    > label {
      top: 50%;
      left: 15px;
      position: absolute;
      // background-color: white;
      padding: 0px 5px 0px 5px;
      font-size: 1em;
      transition: 0.2s;
      pointer-events: none;
      text-align: left !important;
      transform: translateY(-50%);
    }
    &.label-animate > label {
      transform: translateY(0%);
      top: -10px !important;
      left: 16px !important;
      font-size: 0.9em;
    }
  }
}
