.databowl {
  .hero {
    .row {
      .col-md-6.col-xl-4.mb-md-5.py-lg-5 {
        @extend .offset-lg-1;
      }
    }
  }
}

.success,
.success-single {
  .hero {
    padding-bottom: 0 !important;
    .row {
      .col-md-6.col-xl-4.mb-md-5.py-lg-5 {
        padding-bottom: 0 !important;
        text-align: center;
      }
      .col-xl-6 {
        display: none !important;
      }
    }
  }
}

.success-single {
  .hero {
    padding-bottom: 0 !important;
    .col-md-6.col-xl-4.mb-md-5.py-lg-5 {
      padding-bottom: 0 !important;
    }
  }
  .imagetext-block-image {
    @extend .col-lg-4;
    .drop-shadow-sm {
      box-shadow: unset;
      filter: unset !important;
    }
  }
  .imagetext-block-text {
    @extend .offset-lg-1, .align-self-center;
  }

  .large-quote {
    @extend .bg-light;
    blockquote {
      @extend .text-primary;
    }
  }
}

.contact {
  .hero {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    .col-md-6.col-xl-4.mb-md-5.py-lg-5 {
      @extend .col-xl-6;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .typeform {
    .col-lg-9.col-xl-7 {
      @extend .col-lg-11, .col-xl-10;
    }
  }
  .columns {
    @extend .pt-0;
    .row {
      @extend .pt-0;
    }
  }
  iframe {
    box-shadow: unset !important;
  }
}

.validation-calculator,
// .solutions-for-lead-generators, 


.contact,
.pricing {
  @extend .bg-light;
  .hero {
    .row {
      @extend .text-center, .justify-content-center;
      .col-md-6.col-lg-5.col-xl-6 {
        display: none;
      }
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}

.pricing {
  &_cards {
    @include media-breakpoint-up(lg) {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: -20%;
        width: 140%;
        height: 100%;
        // background-image: url(/img/prices.png);
        background-position: 0 20%;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: -1;
      }
    }
  }
  position: relative;
  &_background {
    position: absolute !important;

    .gatsby-image-wrapper {
      display: none;

      @include media-breakpoint-up(lg) {
        position: absolute;
        display: block;
        top: 20rem;
        left: 0%;
      }
    }
  }
  //   }

  .card {
    &-header {
      @include media-breakpoint-up(lg) {
        height: 10rem;
      }
      @include media-breakpoint-up(xl) {
        height: 8rem;
      }
    }
  }

  .pricing-table {
    &-pricing {
      margin-top: 2rem;
      @include media-breakpoint-up(md) {
        margin-top: -4rem;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -6rem;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -12rem;
      }
      // @extend .negative-margin_inner;
    }
  }

  .drop-shadow {
    filter: none;
  }
}



// .pricing{
//   table{
//     @include media-breakpoint-down(sm) {
//       font-size: 0.75rem;
//         th.text-left{
//           display: block;
//           width: 100% !important;
//           text-align: center!important;
//         }
//         tr{
//           display: block;
//           width: 100%;
//           margin-bottom: 2rem;
//         }
//         th.tier-2,
//         th.tier-3,
//         th.tier-4,
//         td.tier-2,
//         td.tier-3,
//         td.tier-4
//         {
//           width: 33vw;
//         }
//     }
//   }


// }