// @import '~aos/src/sass/aos';
// @import "~aos/dist/aos";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/bootstrap/scss/functions";
@import "./mixins/remcalc";
@import "./mixins/yiq";
@import "./mixins/background-opacity";


@import "./themes/databowl";

@import "../../node_modules/bootstrap/scss/variables";


@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";  
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
// @import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";




@import "./helpers/helpers";


@import "./components/intercom";

@import "./components/nav";
@import "./components/hero";
@import "./components/home_success";
@import "./components/pricing";
@import "./components/journal_content";

@import "./components/success-card";

@import "./components/form";



// @import "./components/carousel";
// @import "./components/multistep";
// @import "./components/review_carousel";
// @import "./components/chatbot";
// @import "./components/stickycontainer";


@import "./hacks/IE11";
// @import "./hacks/databowl";

@import "./custom/fontawesome";

@import "./custom/style";




